window.addEventListener('scroll', function (e) {
  const largeDisplay = window.matchMedia('(min-width: 1025px)');
  if (largeDisplay.matches) {
    var scrolled = window.pageYOffset;
    const productVisualAssetD = document.querySelector(
      '#kin-consulting .scrollfx-a'
    );
    productVisualAssetD.style.bottom = -(scrolled * 0.085 - 100) + 'px';
  }
});
